
const Footer = () => {

    return (
        <footer className="b--footer-a b--footer-a__second">
            <div className="f--col-12 text-center">
                <img className="b--footer-a__logo" src="Logos-ps4-ps5-mobile.png" alt=""></img>
            </div>
            <div className="f--col-12 text-center">
                <a className="b--footer-a__link" href="termes-et-conditions.pdf" target="_blank" rel="noreferrer">Termes et Conditions</a>
            </div>
            
        </footer>
    )
}

export default Footer