import React from 'react'
import MaskedInput from 'react-maskedinput'

class DateOfBirth extends React.Component {

  render() {
    return (
        <div className="b--form-group-a">
            <div className="b--form-input-a input-date">
                <MaskedInput mask="11/11/1111" name="date_of_birth" placeholder="Date de naissance*" className="b--form-input-a__item" />
                <span className="b--form-error-a error-input display-block">Ce champ est obligatoire.</span>
                <span className="b--form-error-a error-wrong display-block">Ce champ n'est pas rempli correctement.</span>
                <span className="b--form-error-a error-underage display-block">Vous devez avoir au moins 18 ans pour participer.</span>
            </div>
            
        </div>
    )
  }
}

export default DateOfBirth